import { api } from '@/api'
import { composeGetQuery } from '@/services/utils'

export const getActivities = async (options, apiOpts) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get('activity', { params }, apiOpts)

  return apiResponse.data
}

export const exportActivitiesData = async (options, apiOpts) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get('activity/export', { params }, apiOpts)
  return apiResponse.data
}
