<template>
  <div>
    <list
      :loading="loading"
      :options.sync="options"
      :items="items"
      @loadData="loadData"
    >
      <template v-slot:item="{ item }">
        <slot
          name="item"
          :item="item"
        />
      </template>
      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]
      >
        <slot :name="slot" />
      </template>
    </list>
  </div>
</template>

<script>
  import * as activitySrvc from '@/components/common/services/activities'

  export default {
    name: 'ListWrapper',

    components: {
      List: () => import('./List'),
    },

    props: {
      preFilters: {
        type: Object,
        default: () => {},
      },
      doReload: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      queryLoaded: false,
      options: {
        filters: {
        },
        pager: {
          itemsPerPage: 10,
          page: 1,
          total: 1,
        },
        sort: {
          sortBy: ['created_on'],
          sortDesc: [false],
        },
      },
      loading: false,

      items: [],
    }),

    computed: {
    },

    watch: {
      preFilters: {
        handler () {
          this.options.filters = {
            ...this.options.filters,
            ...this.preFilters,
          }
        },
        deep: true,
        immediate: true,
      },
      options: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
      doReload: {
        handler (val) {
          if (val && !this.loading) {
            this.loadData()
          }
        },
        immediate: true,
      },
    },

    created () {
    },

    methods: {
      async loadData () {
        this.loading = true
        try {
          const apiResponse = await activitySrvc.getActivities({
            sortDesc: this.options.sort.sortDesc,
            sortBy: this.options.sort.sortBy,
            page: this.options.pager.page,
            itemsPerPage: this.options.pager.itemsPerPage,
            filters: {
              ...this.options.filters,
            },
            // query
          }, { base: 'common' })
          this.items = apiResponse.results
          this.options.pager.total = Math.ceil(apiResponse.count / this.options.pager.itemsPerPage)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
        this.loading = false
      },
    },
  }
</script>
<style lang="scss">
</style>
