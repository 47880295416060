<template>
  <div>
    <v-dialog
      v-model="internalDialog"
      :hide-overlay="false"
      transition="scroll-x-reverse-transition"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <common-trs-modal-card>
        <common-trs-modal-card-title>
          <common-trs-modal-card-title-content>Alerts</common-trs-modal-card-title-content>
          <common-trs-btn
            aria-label="Close"
            type="secondary"
            class="rounded-0"
            color="primary"
            size="large"
            icon
            text
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </common-trs-btn>
        </common-trs-modal-card-title>

        <common-trs-modal-card-text>
          <v-tabs
            v-model="tabIndex"
          >
            <v-tab
              key="activity"
              :ripple="false"
            >
              Activity
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabIndex">
            <v-tab-item
              key="activity"
            >
              <activities-wrapper-list-wrapper
                :pre-filters="{ created_by: user.username }"
                :do-reload="internalDialog"
              >
                <template v-slot:item="{ item }">
                  <activities-wrapper-dense-item
                    :item="item"
                  />
                </template>
              </activities-wrapper-list-wrapper>
            </v-tab-item>
          </v-tabs-items>
        </common-trs-modal-card-text>
      </common-trs-modal-card>
    </v-dialog>
  </div>
</template>

<script>
  import ActivitiesWrapperListWrapper from '@/components/common/activities-wrapper/ListWrapper'
  import ActivitiesWrapperDenseItem from '@/components/common/activities-wrapper/DenseItem'
  export default {
    name: 'Alert',

    components: {
      ActivitiesWrapperListWrapper,
      ActivitiesWrapperDenseItem,
    },

    data: () => ({
      tabIndex: 0,
    }),

    computed: {
      user () {
        return this.$store.getters['auth/user']
      },
      internalDialog: {
        get () {
          return this.$store.getters['app/showAlertModal']
        },
        set (val) {
          return this.$store.commit('app/setShowAlertModal', val)
        },
      },
    },

    watch: {
    },

    created () {
    },

    methods: {
      close () {
        this.internalDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .common-side-modal {
    margin: 0;
    max-height: 100vh;
    justify-self: flex-end;
    right: 0;
    left: auto;
    position: fixed;
    height: 100vh;
    max-width: 350px;
    background: #FFF;
  }
</style>
