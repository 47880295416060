<template>
  <v-card
    class="card-item ma-0"
    elevation="0"
  >
    <div>
      <v-row no-gutters>
        <v-col
          cols="12"
        >
          <v-card
            class="pa-2"
          >
            <div
              class="request"
            >
              <div
                class="grey--text font-weight-light body-text"
              >
                On {{ $date(item.created_on) }} at {{ $time(item.created_on) }}
              </div>
            </div>
            <div
              class="response"
            >
              <div
                class="font-weight-light body-text"
              >
                {{ item.log }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'CardItem',

    components: {
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
    }),

    computed: {
    },

    watch: {
    },

    created () {
    },

    methods: {
    },
  }
</script>
<style lang="scss" scoped>
  .card-item {
  }
</style>
